import defaultTheme from '@bluheadless/ui/src/theme/default'
import { fonts } from '../fonts'

const commonStyles = {
	minHeight: '45px',
	minWidth: 'fit-content',
	borderRadius: '0px',
	textTransform: 'uppercase',
	fontFamily: 'var(--font-base-semibold)',
	fontSize: 'var(--button-primary-size)',
	lineHeight: 'var(--button-primary-size)',
	letterSpacing: '0.2px',
	padding: '13px 43px',
	boxShadow: 'none',
	borderWidth: '2px',
	textAlign: 'center',
	'&:hover': {
		borderColor: 'currentColor',
	},
	'&:focus': {
		borderColor: 'currentColor',
	},
	'&:active': {
		borderColor: 'currentColor',
	},
	'&.Mui-disabled': {},
	'& .MuiButton-startIcon': {
		fill: 'currentColor',
		marginLeft: 0,
		marginRight: '8px',
	},
	'& .MuiSvgIcon-root': {},
	'& .MuiButton-endIcon': {
		fill: 'currentColor',
		marginLeft: '8px',
		marginRight: 0,
		display: 'none',
	},
}
const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				...fonts.PlusJakartaSans,
				...commonStyles,
				'&.MuiLink-underlineNone': {
					textDecoration: 'none',
				},
			},
			contained: {
				minWidth: 'unset',
			},
			containedPrimary: {
				...commonStyles,
				color: 'var(--color-secondary)',
				background: 'var(--color-primary)',
				fontFamily: 'var(--font-base-bold)',
				fontWeight: 800,
				letterSpacing: '2px',
				'&:hover': {
					background: 'var(--color-grey-button-primary)',
					borderColor: 'var(--color-grey-button-primary)',
				},
				'&:focus': {
					background: 'var(--color-grey-button-primary)',
					borderColor: 'var(--color-grey-button-primary)',
				},
				'&:active': {
					background: 'var(--color-grey-button-primary)',
					borderColor: 'var(--color-grey-button-primary)',
				},
				'&.Mui-disabled': {
					background: 'var(--color-grey70)',
					borderColor: 'var(--color-grey70)',
				},
			},
			containedSecondary: {
				...commonStyles,
				color: 'var(--color-primary)',
				background: 'var(--color-secondary)',
				fontFamily: 'var(--font-base-bold)',
				letterSpacing: '2px',
				mixBlendMode: 'screen',
				'&:hover': {
					background: 'var(--color-grey-button-secondary)',
					borderColor: 'var(--color-grey-button-secondary)',
				},
				'&:focus': {
					background: 'var(--color-grey-button-secondary)',
					borderColor: 'var(--color-grey-button-secondary)',
				},
				'&:active': {
					background: 'var(--color-grey-button-secondary)',
					borderColor: 'var(--color-grey-button-secondary)',
				},
				'&.Mui-disabled': {
					background: 'var(--color-grey70)',
					color: 'var(--color-grey60)',
				},
			},
			outlined: {
				...commonStyles,
			},
			outlinedPrimary: {
				backgroundColor: 'var(--color-secondary)',
				color: 'var(--color-primary)',
				borderColor: 'var(--color-primary)',
				'&:hover': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-primary)',
				},
				'&:active': {
					backgroundColor: 'var(--color-primary)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey60)',
					background: 'var(--color-grey70)',
				},
			},
			outlinedSecondary: {
				color: 'var(--color-primary)',
				borderColor: 'var(--color-primary)',
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-primary)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-primary)',
				},
				'&:active': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-primary)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey60)',
					background: 'var(--color-grey70)',
				},
			},
			text: {
				...commonStyles,
				padding: '4px 0',
				minWidth: 'unset',
				minHeight: 'unset',
				borderWidth: '1px',
				borderRadius: '0px',
				lineHeight: 'var(--size-small)',
				position: 'relative',
				overflow: 'hidden',
				letterSpacing: '0.1px',
				'&:hover': {
					borderWidth: '1px',
					borderColor: 'currentColor',
				},
				'&:focus': {
					borderWidth: '1px',
					borderColor: 'currentColor',
				},
				'&:active': {
					borderWidth: '1px',
					borderColor: 'currentColor',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey30)',
					borderColor: 'var(--color-grey30)',
					borderWidth: '1px',
				},
				'& .MuiSvgIcon-root': {
					fontSize: '16px',
				},
				'&.icon': {
					display: 'inline-block',
					'&::after': {
						content: `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS40NzM3IDE3LjAyNjJMMTQuMzQyMyAxNS44OTQ4TDE4LjIzNzIgMTEuOTk5OUwxNC4zNDIzIDguMTA1MDFMMTUuNDczNyA2Ljk3MzYzTDIwLjUgMTEuOTk5OUwxNS40NzM3IDE3LjAyNjJaIiBmaWxsPSJibGFjayIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuNSAxMS4yOTk5TDE4LjYyNTIgMTEuMjk5OVYxMi44OTk5TDEuNCAxMi44OTk5TDEuNCAxMS4yOTk5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==')`,
						display: 'inline-block',
						width: '20px',
						height: '20px',
						marginLeft: '8px',
						transform: 'translateX(0) scale(0.8)',
						transition: 'var(--transition)',
						verticalAlign: 'bottom',
					},
				},
			},
			textPrimary: {
				color: 'var(--color-primary)',
				'&::after': {
					background: 'var(--color-primary)',
				},
				'&:hover,&:focus,&:active': {
					color: 'var(--color-primary)',
				},
			},
			textSecondary: {
				color: 'var(--color-secondary)',
				'&::after': {
					background: 'var(--color-secondary)',
				},
				'&:hover,&:focus,&:active': {
					color: 'var(--color-secondary)',
				},
			},
			textLink: {
				color: 'var(--color-primary)',
				borderColor: 'var(--color-primary)',
				'&:hover,&:focus,&:active': {
					color: 'var(--color-grey30)',
					borderColor: 'var(--color-grey30)',
				},
				'&.emptyCartButton': {
					'--button-primary-size': 'var(--size-medium)',
				},
			},
			textGrey: {
				'--button-primary-size': 'var(--size-base)',
			},
			sizeSmall: {
				fontSize: 14,
				padding: '12px 20px',
			},
			textNoLink: {
				...fonts.plusJakartaSansSemiBold,
				fontSize: 'var(--size-base)',
				lineHeight: '24px',
				letterSpacing: 'calc(var(--size-base) + 10%)',
				fontWeight: 600,
				textTransform: 'uppercase',
				minHeight: 'unset',
				minWidth: 'unset',
				[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
					fontSize: 'var(--size-base)',
				},
			},
		},
	},
	MuiBadge: {
		styleOverrides: {
			colorPrimary: {
				borderWidth: '1px',
			},
			standard: {
				minHeight: 15,
				minWidth: 15,
				transform: 'scale(1) translate(4.5px, 0)',
				fontSize: '8px',
			},
		},
	},
}
export default Button
