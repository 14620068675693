const Chip = {
	MuiChip: {
		styleOverrides: {
			deleteIcon: {
				color: 'var(--color-primary)',
				fontSize: 'var(--size-big)',
				margin: '0 0 0 calc(var(--spacing-1) / 2)',
			},
			label: {
				paddingLeft: 0,
				paddingRight: 0,
				lineHeight: '18px',
				textTransform: 'uppercase',
				letterSpacing: 3,
				fontFamily: 'var(--font-base-bold)',
				fontSize: 'var(--size-small)',
			},
			root: {
				borderRadius: 24,
				fontSize: 'var(--size-base)',
				height: 24,
				paddingLeft: 'var(--spacing-1)',
				paddingRight: 'var(--spacing-1)',
			},
		},
	},
}

export default Chip
