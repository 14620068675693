const BHRelated = {
	BHRelated: {
		defaultProps: {
			showTitle: true,
			skeletonProps: { columnGap: 3 },
			sliderProps: {
				breakpoints: {
					xs: {
						spaceBetween: 3,
					},
					sm: {
						spaceBetween: 3,
					},
					md: {
						spaceBetween: 4,
					},
				},
				controlsPosition: 'default',
				spaceBetween: 24,
			},
		},
	},
}

export default BHRelated
