import { default as defaultTheme, default as globalTheme } from '@bluheadless/ui/src/theme/default'
import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			':root': {
				colorScheme: 'light dark',
				'--spacing': '8px',
				'--spacing-1': 'calc(var(--spacing) * 1)',
				'--spacing-2': 'calc(var(--spacing) * 2)',
				'--spacing-3': 'calc(var(--spacing) * 3)',
				'--spacing-4': 'calc(var(--spacing) * 4)',
				'--spacing-5': 'calc(var(--spacing) * 5)',
				'--spacing-6': 'calc(var(--spacing) * 6)',
				'--spacing-7': 'calc(var(--spacing) * 7)',
				'--spacing-8': 'calc(var(--spacing) * 8)',
				'--spacing-9': 'calc(var(--spacing) * 9)',
				'--spacing-10': 'calc(var(--spacing) * 10)',
				// fallback
				'--size-8': 'var(--spacing)',
				'--size-10': 'calc(var(--spacing) * 1.25)',
				'--size-12': 'calc(var(--spacing) * 1.5)',
				'--size-14': 'calc(var(--spacing) * 1.75)',
				'--size-16': 'var(--spacing-2)',
				'--size-18': 'calc(var(--spacing) * 2.25)',
				'--size-20': 'calc(var(--spacing) * 2.5)',
				'--size-22': 'calc(var(--spacing) * 2.75)',
				'--size-24': 'var(--spacing-3)',
				'--size-28': 'calc(var(--spacing) * 3.5)',
				'--size-30': 'calc(var(--spacing) * 3.75)',
				'--size-32': 'var(--spacing-4)',
				'--size-40': 'calc(var(--spacing) * 5)',
				// font-family
				'--font-base-light': '"PlusJakartaSans-Light"',
				'--font-base-regular': '"PlusJakartaSans"',
				'--font-base-medium': '"PlusJakartaSans-Medium"',
				'--font-base-semibold': '"PlusJakartaSans-SemiBold"',
				'--font-base-bold': '"PlusJakartaSans-Bold"',
				'--font-base-extrabold': '"PlusJakartaSans-ExtraBold"',
				// brand
				'--color-brand': '#9E374E',
				// default
				'--color-primary': '#000000',
				'--color-secondary': '#ffffff',
				// others
				'--color-grey0': '#F0F0F0',
				'--color-grey10': '#2A2A2A',
				'--color-grey20': '#525252',
				'--color-grey30': '#757575',
				'--color-grey40': '#9A9A9A',
				'--color-grey50': '#B8B8B8',
				'--color-grey60': '#CFCFCF',
				'--color-grey70': '#E1E1E1',
				'--color-grey80': '#EEEEEE',
				'--color-grey90': '#F8F8F8',
				'--color-grey100': '#F2F2F2',
				'--color-grey110': '#F2F1EF',
				'--color-grey120': '#A0A0A0',
				'--color-brown': '#704214',
				'--color-green': '#008000',
				'--color-blue': '#0D31AB',
				'--color-purple': '#800080',
				'--color-pink': '#FFB6C1',
				'--color-red': '#FF0000',
				'--color-orange': '#F28413',
				'--color-yellow': '#FFE400',
				'--color-white': '#FFFFFF',
				'--color-black': '#000000',
				'--color-grey-button-primary': '#343434',
				'--color-grey-button-secondary': '#EAEAEA',
				'--color-grey-tile': '#F7F7F7',
				// buttons
				'--button-primary-size': '14px',
				'--button-primary-color': 'var(--color-secondary)',
				'--button-primary-bgcolor': 'var(--color-primary)',
				'--button-primary-hover-bgcolor': 'var(--color-grey10)',
				'--button-secondary-size': '14px',
				'--button-secondary-color': 'var(--color-primary)',
				'--button-secondary-bgcolor': 'var(--color-secondary)',
				// animations
				'--transition': '.3s cubic-bezier(0.4, 0, 0.2, 1) 0s',
				'--swiper-theme-color': 'var(--color-primary)',
				// maps
				'--maps-cluster-bg-color': 'var(--color-primary)',
				// various
				'--right-anchored-drawer-width': '400px',
			},
			html: {
				...fonts.PlusJakartaSans,
				'--size-small': globalTheme.typography.pxToRem(10),
				'--size-base': globalTheme.typography.pxToRem(12),
				'--size-medium': globalTheme.typography.pxToRem(14),
				'--size-big': globalTheme.typography.pxToRem(16),
			},
			body: {
				...fonts.PlusJakartaSans,
				fontFamily: 'var(--font-base-regular)',
				color: 'var(--color-primary)',
				'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
					{
						WebkitBoxShadow: '0 0 0 30px white inset !important',
						WebkitTextFillColor: 'var(--color-primary) !important',
					},

				'& .d-none-desktop': {
					[defaultTheme.breakpoints.up('md')]: {
						display: 'none !important',
					},
				},

				'& .cart-page, .checkout-page': {
					'> .MuiContainer-root.main-layout-container': {
						padding: '0 var(--spacing-1)',
						[defaultTheme.breakpoints.up('md')]: {
							padding: '0 var(--size-14)',
						},
					},
					'.rassurance_info': {
						...fonts.PlusJakartaSans,
						marginBottom: 'var(--size-20)',
						h4: {
							margin: 'var(--spacing-1) 0',
							lineHeight: '20px',
						},
					},
				},
				'.zoom-active-slide': {
					'.swiper-wrapper': {
						'.swiper-slide': {
							transition: 'transform .3s',
							transform: 'scale(0.9)',
							'.text-column-wrapper': {
								opacity: 0,
								transition: 'opacity .3s',
							},
							'&.swiper-slide-active': {
								transformOrigin: 'right',
								transform: 'scale(1)',
								'.text-column-wrapper': {
									opacity: 1,
								},
							},
						},
					},
				},
				'.align-center': {
					textAlign: 'center',
					'&-m': {
						textAlign: 'left',
						[`@media screen and (max-width: ${defaultTheme.breakpoints.values.md - 1}px)`]: {
							textAlign: 'center',
						},
					},
					'&-d': {
						textAlign: 'left',
						[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
							textAlign: 'center',
						},
					},
				},
			},
			hr: {
				border: 'none',
				borderTop: '1px solid var(--color-grey60)',
			},
		},
	},
}

export default CssBaseline
