const Dialog = {
	MuiDialog: {
		styleOverrides: {
			root: {
				'&.SizeGuide-root': {
					'& .MuiPaper-root': {
						minHeight: 'initial',
					},
				},
				'&:has(.QuickBuy-root) .MuiPaper-root': {
					padding: 0,
				},
			},
			paperWidthLg: {
				padding: 'var(--spacing-2)',
			},
		},
	},
}

export default Dialog
