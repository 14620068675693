/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SearchIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 2"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<g data-name="Livello 1">
			<path d="M18.5 19a.47.47 0 01-.35-.15l-4-4c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4 4c.2.2.2.51 0 .71-.1.1-.23.15-.35.15z" />
			<path d="M8.5 17C3.81 17 0 13.19 0 8.5S3.81 0 8.5 0 17 3.81 17 8.5c0 2.27-.88 4.4-2.48 6A8.449 8.449 0 018.5 17zm0-16C4.36 1 1 4.36 1 8.5S4.36 16 8.5 16c2.01 0 3.89-.78 5.31-2.2A7.503 7.503 0 008.5 1z" />
		</g>
	</svg>
))
SearchIconSvg.displayName = 'SearchIconSvg'

const SearchIcon = forwardRef((props, ref) => <Icon component={SearchIconSvg} ref={ref} {...props} />)
SearchIcon.displayName = 'SearchIcon'

SearchIcon.defaultProps = {
	...Icon.defaultProps,
}
SearchIcon.propTypes = {
	...Icon.propTypes,
}

export default SearchIcon
export { SearchIconSvg }
